body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{font-weight: 500;}

img{
  max-width: 100%;
}

#notice-img img{
  object-fit: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-style {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}

.img-style-scanner {
  height: 40px;
  width: 30px;
  flex-shrink: 0;
}

.icon-img-style {
  width: 35px;
  flex-shrink: 0;
}

.expand-box-animation {
  float: right;
  width: 100%;
  animation: expandBox 0.3s ease-in 1;
}

.switch-on {
  height: 42px;
  width: 42px;
  border-radius: 48px;
  background: #D99948;
  right: 0;
  /* box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF; */
}

.switch-off {
  height: 42px;
  width: 42px;
  border-radius: 48px;
  background: #b6b6b6;
  left: 0;
  /* box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF; */
}

@keyframes expandBox {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

/* --------------------------------  BonusListing  ------------------------------------------ */
.bonusItemAlign {
  display: flex;
  align-items: flex-start;
}

.bonusItemAlign:nth-child(odd) {
  justify-content: flex-start;
}

.bonusItemAlign:nth-child(even) {
  justify-content: flex-end;
}

.plus-btn {
  width: 50px;
  height: 50px;
  border-radius: 48px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset;
  transform: scale(1);
  transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}

.plus-btn:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}

/* -------------------------------------------------------------------------- */
.shadow-in {
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.08) inset, 3px 3px 4px 0px #FFF;
}

.shadow-out {
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.11), 2px 2px 2px 0px rgba(255, 255, 255, 0.89) inset;
}
.divider-theme, .divider-white .divider-black{
  height: 7px;
  margin: 5px 0;
  border-radius: 48px;
}
.divider-theme {
  background: #8e5213;
  box-shadow: 0px 3px 4px 0px rgb(0 0 0 / 32%) inset, 0px 2px 3px 0px #ffffff2e;
}

.divider-white {
  background: #F1F1F1;
  box-shadow: 0px 2px 3px 0px #FFF, 0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset;
}

.divider-black {
  background: var(--Divider-Bkg, 'transparent');
  box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.86) inset;
}

.divider-list-black {
  background: 'transparent';
  box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 0.07), 0px 1px 2px 0px rgba(0, 0, 0, 0.86) inset;
  height: 4px;
  margin: 5px 0;
}

.list-divider {
  border-bottom: 3px solid #000000DB ;
  border-top: 3px solid #FFFFFF12;
}

.list-divider:last-child {
  border-bottom: none;
}

.list-divider:first-child {
  border-top: none;
}

.user-style {
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-size: cover
}

.news-box {
  border-radius: 8px;
  padding: 10px;
  background: #DDD;
  /* box-shadow: inset 3px 4px 6px 0 #0003, 3px 3px 6px 0 #fff; */
  padding: 10px;
  width: calc(100% - 40px);
  height: 95px;
}

.news-img-pos {
  width: 90px;
  position: absolute;
  left: 10px;
  top: -11px;
  height: 90px;
  object-fit: contain;
  display: flex;
}

.server-box {
  border-radius: 8px;
  background: #1E1E1E;
  /* box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.10), -2px -2px 3px 0px #FFF */
}

.small-button {
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 3px 16px !important;
  background: linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  border-width: 1px;
  border-color: transparent;
}

.small-button:disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background: rgba(0, 0, 0, 0.12);
}

.medium-button-selected {
  background-color: #565656 !important;
  box-shadow: 3px 3px 3px 0px #00000026 inset, 2px 2px 4px 0px #FFFFFF;
}

.big-button {
  max-width: 100%;
  min-height: 45px;
  background: linear-gradient(130deg, #a3732f, #b88e4b, #ead290, #ffedab, #f2de9d, #82561d, #b88e4b, #cea253, #ecc173, #ffdc9d, #ffeaa5) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  border-width: 1px;
  border-color: transparent;
}

.big-button:disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background: rgba(0, 0, 0, 0.12);
}

.max-button {
  border-radius: 8px !important;
  min-width: 80px !important;
  font-weight: 400;
  font-size: 17px;
}

.button-border {
  border: 2px solid transparent;
  background:
    linear-gradient(#111115,#111115) padding-box,
    linear-gradient(280deg,#b58947,#a3732f,#361f00,#ffeaa5,#cea253,#82561d,#a07a3f,#d3b879,#f2de9d,#ffedab,#ead290,#b88e4b,#a3732f)!important;
  color: #fde38d !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wallet-slider .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background: #D9D9D9;
  /* box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25) inset; */
  filter: drop-shadow(1px 1px 1px #FFF);
}

.wallet-slider .swiper-pagination-bullet-active {
  background: #F0F0F0;
}

.wallet-slider {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.withdraw-ol {
  padding: 15px;
  margin: 0px;
}

.withdraw-ol li::marker {
  color: #787878;
  font-size: 12px;
}

/* -------------------------------------------------------------------------- */
.hidden-scroll {

  -ms-overflow-style: none;

  overflow: -moz-scrollbars-none;

}

.hidden-scroll::-webkit-scrollbar {

  display: none;

}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F0F0F0;
  border-radius: 49px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FFFFFF;
}

.tab-content {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.tab-content.hidden-left {
  opacity: 0;
  transform: translateX(-100%);
}

.tab-content.hidden-right {
  opacity: 0;
  transform: translateX(100%);
}