.main-user{
  /* background: linear-gradient(109deg, #B17435 3.41%, #DEA96C 98.09%); */
  /* background: var(--dark-red, #B51111); */
  background: linear-gradient(#12110c, #080805, #000000) padding-box, linear-gradient(to right, #b58947, #a3732f, #361f00, #ffeaa5, #cea253, #82561d, #a07a3f, #d3b879, #f2de9d, #ffedab, #ead290, #b88e4b, #a3732f) border-box;
  border: 2px solid transparent;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25) inset; 
  border-radius: 15px;
  color:#fff;
  padding:15px
}

.rank-main-icon{
  height: 45px;
  width: 45px;
  border-radius: 100%;
  padding: 10px;
  background: #383838;
  box-shadow: inset 2px 1px 3px 0 #000000;
}

.main-list, .main-list-point{
  font-size: 15px;
  line-height: 1.2;
  padding-bottom: 10px;
}
.main-list-point{
  text-align: center;
}

.rank-icon-left, .rank-icon-right{
  height:40px;
  aspect-ratio: 1/1;
  width: 40px;
  border-radius:8px;
}

.rank-sub-icon-left, .rank-sub-icon-right{
  height: 35px;
  width: 35px;
  min-width: 35px;
  border-radius: 100%;
  padding: 7px;
}
.rank-sub-icon-left, .rank-icon-left{
  background: #e2cb92;
  box-shadow: inset 2px 3px 3px 0 #00000036, 2px 2px 3px 0 #ffffff4d;
}
.rank-sub-icon-right, .rank-icon-right{
  background: #333333;
  box-shadow: inset 2px 3px 3px 0 #000, 2px 2px 3px 0 #ffffff1f;
}

.sub-list, .sub-list-point{
  font-size: 13px;
  line-height: 1.1;
  padding-bottom:15px;
  font-weight: bold;
  white-space: break-spaces;
}
/* .sub-list-point{
  text-align: right;
} */

.sub-user-left, .sub-user-right{
  width:48%;
}
.sub-right-bkg, .sub-left-bkg{
  border-radius: 15px;
  padding:15px;
}
.sub-left-bkg{
  /* background:linear-gradient(148deg, #e2cb92 0%, #d3bb7c 100%); */
  /* color:#8c713b; */
  background: #cb9b4a;

  /* background:linear-gradient(148deg, #F3AF2C 0%, #D1913C 100%); */
  color:#2E3133;
}

.sub-right-bkg{
  /* background:linear-gradient(148deg, #333333 0%, #282828 100%); */
  /* color:#cecece; */
  background: #2d2d2d;

  /* background:linear-gradient(148deg, #DEBC98 0%, #EAB376 100%); */
  color:#fff;
  /* background:linear-gradient(148deg, #345bd6 0%, #5d58ff 100%); */
}

.sub-left-border{
  border: 1px dashed #2f3133;
}

.sub-right-border{
  border: 1px dashed #ffffff;
}
/* .sub-selected-bkg{
  background:linear-gradient(148deg, #FF9956 0%, #FFCE37 100%);
} */

.genealogy-shadow-in{
  box-shadow: inset 4px 4px 2px 0 #4e4e4e2e, inset -4px -3px 3px 0 #ffffff0a;
}
.sub-user-left .genealogy-shadow-out {
  box-shadow: inset 4px 1px 8px 0 #ffffff6e, 4px 4px 4px 0 #7b7a7a8f;
}
.sub-user-right .genealogy-shadow-out{
  box-shadow: inset 4px 2px 8px 0 #ffffff1a, 4px 4px 4px 0 #2323238f;
}

.search-style{
  border-radius: 48px;
  box-shadow: -2px -2px 4px rgb(0 0 0), 2px 2px 4px #ffffff29;
  /* border-right: 1px solid #e9e9e9; */
}

.downline-button-left, .downline-button-right {
  border: 2px solid;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 1.3rem;
  aspect-ratio: 1/1;
  border-radius: 48px;
  box-shadow: 2px 2px 4px 0 #89662640;
}

.downline-button-left {
  /* color: #8c713b; */
  /* border-color: #8c713b; */
  /* background: #e2cb92; */
  color: #2f3133;
  border-color: #82512D;
  background: #f0f0f0;
}

.downline-button-right {
  /* color: #cecece; */
  /* border-color: #cecece; */
  /* background: #333333; */
  color: #fff;
  border-color: #fff;
  background: #D1913C;
}

.upline-button {
  border: 2px solid;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 1.3rem;
  aspect-ratio: 1/1;
  border-radius: 48px;
  box-shadow: 2px 2px 4px 0 #89662640;
  color: #fff;
  border-color: #cea253;
  background: #141418;
}

.chart-border-color{
  border-color: #707070!important;
}

.outer-white-circle-design{
  border: 1px solid #fff;
  border-radius: 49px;
  width: 50px;
  height: 50px;
  aspect-ratio: 1/1;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.sub-left-outer-white-circle-design{
  border: 1px solid #2E3133;
  border-radius: 49px;
  width: 50px;
  height: 50px;
  aspect-ratio: 1/1;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.sub-right-outer-white-circle-design{
  border: 1px solid #fff;
  border-radius: 49px;
  width: 50px;
  height: 50px;
  aspect-ratio: 1/1;
  display: flex; 
  align-items: center;
  justify-content: center;
}


.inner-white-circle-design{
  background: #fff;
  border-radius: 49px;
  padding: 10px;
  margin: 10px;
  width: 42px;
  height: 42px;
  aspect-ratio: 1/1;
}

.sub-left-inner-white-circle-design{
  background: #2E3133;
  border-radius: 49px;
  padding: 10px;
  margin: 10px;
  width: 42px;
  height: 42px;
  aspect-ratio: 1/1;
}

.sub-right-inner-white-circle-design{
  background: #fff;
  border-radius: 49px;
  padding: 10px;
  margin: 10px;
  width: 42px;
  height: 42px;
  aspect-ratio: 1/1;
}

.white-circle-text-main, .white-circle-text-left, .white-circle-text-right{
  text-align: center;
  font-weight: bold;
}

.white-circle-text-main{
  /* color: #B17435; */
  color: #141418;
}

.white-circle-text-left{
  /* color: #e2cb92; */
  color: #F0F0F0;
}

.white-circle-text-right{
  /* color: #333333; */
  color: #1E1E1E;
}